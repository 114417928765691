import $ from 'jquery';

function initChanges() {
  $(document).off('click', '.operator').on('click', '.operator', function() {
    this.textContent = this.textContent.trim() === 'AND' ? 'OR' : 'AND';
  });

  $(document).off('click', '.geek-mode-toggle').on('click', '.geek-mode-toggle', function () {
    $('#geek-mode-panel').toggleClass('d-none');
  });

  // $(document).off('click', '.add-filter svg').on('click', '.add-filter svg', function(event) {
  //   event.stopPropagation(); // Prevent event bubbling

  //   const parentItem = $(this).closest('.draggable-item');

  //   if (parentItem.length) {
  //     // Apply the pop animation effect
  //     parentItem.addClass('pop-effect');
  //     setTimeout(() => {
  //       parentItem.removeClass('pop-effect');
  //     }, 90);

  //     // Extract filter data
  //     const fieldType = parentItem.attr('data-type');
  //     const fieldLabel = parentItem.attr('data-label');
  //     const category = parentItem.attr('data-category');

  //     // Perform the AJAX request to fetch the filter UI and append it to the correct section
  //     $.ajax({
  //       url: '/data_park_filters/new',
  //       type: 'GET',
  //       data: { field_type: fieldType, field_label: fieldLabel, category: category },
  //       headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
  //       dataType: 'script',
  //       success: function(response) {
  //         console.log(`Filter box for '${fieldLabel}' added to category '${category}'`);
  //       },
  //       error: function(xhr, status, error) {
  //         console.error('Error fetching filter box:', error);
  //       }
  //     });
  //   }
  // });

  $(document).off('click', '.category-toggle').on('click', '.category-toggle', function() {
    const svgIcon = $(this).find('svg use');
    const targetCollapse = $($(this).attr("href"));

    if (svgIcon.attr("xlink:href") === "#expand") {
      svgIcon.attr("xlink:href", "#collapse");
    } else {
        svgIcon.attr("xlink:href", "#expand");
    }
  });

  $('.draggable-item').on('dragstart', function(e) {
    draggedField = e.target;
    $(this).css('opacity', '0.5');
  });

  $('.draggable-item').on('dragend', function(e) {
    $(this).css('opacity', '1');
  });

  $('#query-builder').on('dragover', function(e) {
    e.preventDefault();
  });

  let draggedField = null;

  $('.draggable-item')
    .on('dragstart', function(event) {
      draggedField = this;
      $(this).css('opacity', '0.5');
    })
    .on('dragend', function(event) {
      $(this).css('opacity', '1');
    });

  $('#query-builder').on('drop', function(event) {
    event.preventDefault();

    const fieldType = $(draggedField).attr('data-type');
    const fieldLabel = $(draggedField).attr('data-label');
    const category = $(draggedField).attr('data-category');
    $.ajax({
      url: '/data_park_filters/new',
      type: 'GET',
      data: { field_type: fieldType, field_label: fieldLabel, category: category},
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      dataType: 'script',
      success: function() {
        console.log(`Filter box for '${fieldLabel}' added to category '${category}'`);
      },
      error: function(xhr, status, error) {
        console.error('Error fetching filter box:', error);
      }
    });
  });

  $(document).on('submit', '#data-park-form', function (e) {
    e.preventDefault();
    $('#query-json').val(exportQueryBuilderToJSON());

    // Add clicked button's name/value to the form
    const clickedButton = $(document.activeElement);
    if (clickedButton.attr('name') && clickedButton.attr('value')) {
      $('<input>').attr({
        type: 'hidden',
        name: clickedButton.attr('name'),
        value: clickedButton.attr('value'),
      }).appendTo(this);
    }

    this.submit();
  });
}

function removeFilterBoxElements(element) {
  var el = element.closest('.query-box');
  if (el.next('.operator').length > 0) {
    el.next('.operator').remove();
  }else {
    el.prev('.operator').remove();
  }
  el.remove();
}

function adjustVerticalLine() {
  const queryBuilder = document.getElementById("query-builder");
  const verticalLine = document.getElementById("dynamic-vertical-line");
  const categorySections = document.querySelectorAll(".category-section");

  if (categorySections.length > 0) {
    queryBuilder.classList.add("has-categories");

    // Get total height of all category sections
    let totalHeight = 0;
    categorySections.forEach(category => {
      totalHeight += category.offsetHeight + 10; // Add some spacing
    });

    // Set vertical line height
    verticalLine.style.height = totalHeight + "px";
  } else {
    queryBuilder.classList.remove("has-categories");
    verticalLine.style.height = "0px";
  }
}

function exportQueryBuilderToJSON() {
  const $queryBuilder = $('#query-builder');
  const categories = [];
  let categoryOperatorIndex = 0;
  const $categoryOperators = $queryBuilder.find('.operator.category-operator');

  $queryBuilder.find('.category-section').each(function (categoryIndex) {
    const $categorySection = $(this);
    const categoryName = $categorySection.data('category');
    const queries = [];
    let operatorIndex = 0;
    const $operators = $categorySection.find('.operator.filter-operator');

    $categorySection.find('.query-box').each(function (queryIndex) {
      const $box = $(this);
      const label = $box.find('strong').text().trim();
      const comparator = $box.find('.comparator-select').val() || '';
      const $valueInput = $box.find('.value-input');
      let value;

      if ($valueInput.is('select')) {
        value = $valueInput.val();
      } else if ($valueInput.is('input')) {
        value = $valueInput.val();
      }

      queries.push({
        field: label,
        comparator: comparator,
        value: value,
        condition: $operators.eq(operatorIndex).text().trim() || null,
        rank: queryIndex + 1 // Assigning rank to each filter based on order
      });

      if ($operators.eq(operatorIndex).length) {
        operatorIndex++;
      }
    });

    if (queries.length > 0) {
      let categoryObject = {
        category: categoryName,
        queries: queries,
        rank: categoryIndex + 1 // Assigning rank to each category based on order
      };

      // Attach a logical operator between categories if it exists
      if (categories.length > 0 && $categoryOperators.eq(categoryOperatorIndex).length) {
        categoryObject.condition = $categoryOperators.eq(categoryOperatorIndex).text().trim();
        categoryOperatorIndex++;
      }

      categories.push(categoryObject);
    }
  });

  return JSON.stringify(categories, null, 2);
}

$(document).on('turbolinks:load', initChanges);
window.removeFilterBoxElements = removeFilterBoxElements;

document.addEventListener("DOMContentLoaded", function () {
  adjustVerticalLine();
  const observer = new MutationObserver(adjustVerticalLine);
  observer.observe(document.getElementById("query-builder"), { childList: true, subtree: true });
});
